import { onMounted, onUnmounted } from 'vue-demi';
export default function useToTop(): () => void {
  let distance = 0;
  const handleScroll = () => {
    distance = document.documentElement.scrollTop || document.body.scrollTop;
  };
  const goTop = () => {
    const timer = setInterval(() => {
      const ispeed = Math.floor(-distance / 10);
      document.documentElement.scrollTop = document.body.scrollTop =
        distance + ispeed;
      if (distance === 0) {
        clearInterval(timer);
      }
    }, 10);
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
  });
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
  return goTop;
}
