/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
export default defineComponent({
  name: 'RankListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup: function setup() {
    return {};
  }
});