import hyRequest from '@/service';
import {
  ITotalData,
  IRank,
  IDepartData,
  IDepartUserData
} from '@/service/warehouse/requisition/type';
enum DashboardApi {
  getTotalData = '/saleData/reportRequisitionSpareStatistics',
  getRank = '/saleData/reportRequisitionSpareRank'
}

// 获取合计数据
export function getTotalData(data: { date: string }) {
  return hyRequest.post<ITotalData>({
    url: DashboardApi.getTotalData,
    data
  });
}

// 获取部门数据
export function getDepartData(data: { date: string }) {
  return hyRequest.post<IDepartData>({
    url: '/saleData/reportRequisitionSpareDep',
    data
  });
}

// 获取部门用户数据
export function getDepartUserData(data: {
  date: string;
  depName: string;
  userName: string;
}) {
  return hyRequest.post<IDepartUserData>({
    url: '/saleData/reportRequisitionSpareDepUserStone',
    data
  });
}

//获取备品备件排行列表
export function getRank(data: { date: string }) {
  return hyRequest.post<IRank>({
    url: DashboardApi.getRank,
    data: data
  });
}
